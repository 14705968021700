import React, { Component } from "react"
import { ToastContainer, toast, Zoom } from 'react-toastify'
import SEO from "../components/seo"
import SiteContext from "../context/SiteContext"
import Layout from "../components/layout"
import Sidebar from "../components/Sidebar"
import ProductList from "../components/ProductList"
import headerArrows from '../images/header-arrows.png'
import canadaFlag from '../images/CAN.svg'
import usaFlag from '../images/USA.svg'
import lightningIcon from '../images/lightning-icon.svg'
import timeIcon from '../images/time-icon.svg'
import downloadIcon from '../images/download.svg'
import ProtectIntroImage from '../images/mp-intro-img.jpg'
import LowDustBox from "../components/LowDustBox"
import lowDustImg from "../images/low_dust_masterprotect.jpg"

import 'react-toastify/dist/ReactToastify.css'

import Airtable from 'airtable'

const base = new Airtable({ apiKey: 'patNCklhg1H6AionK.9c1220c7fcf2c7b213e5feb66cec8ba4ec36db54743a592d05bc006401577309' }).base('appybfokGG8Y59FxQ')
const notify = () => toast("Data copied to clipboard");
class WelcomeMasterprotect extends Component {

    constructor(props) {
        super(props)
        this.state = {
            site: 'roicalculator',
            siteTitle: 'MasterProtect HB 200 LR ROI Calculator',
            introContent: 'Master Protect HB 200LR is a high-performance reflective coating that helps reduce the number of lights a parking garage needs, thereby providing savings by reducing energy consumption. Using the MasterProtect HB 200LR ROI calculator will allow you to quickly assess energy savings for your parking garage.',
            lowDustBoxTitle: 'MasterProtect HB 200LR',
            lowDustBoxContent: 'MasterProtect HB 200LR is the perfect solution to help reduce energy consumption in your parking garage while protecting it against the corrosive effects of chloride penetration. It does all of this and improves customer safety.',
            lowDustBoxLink: 'https://mbcc.sika.com/en-us/products/wall-coatings/high-build/masterprotect-hb-200lr', 
            selectedCountry: 'usa',
            stateOptions: [
                {"": "Select a state"},
                {"Alabama": "Alabama"},
                {"Alaska": "Alaska"},
                {"Arizona": "Arizona"},
                {"Arkansas": "Arkansas"},
                {"California": "California"},
                {"Colorado": "Colorado"},
                {"Connecticut": "Connecticut"},
                {"Delaware": "Delaware"},
                {"District Of Columbia": "District Of Columbia"},
                {"Florida": "Florida"},
                {"Georgia": "Georgia"},
                {"Hawaii": "Hawaii"},
                {"Idaho": "Idaho"},
                {"Illinois": "Illinois"},
                {"Indiana": "Indiana"},
                {"Iowa": "Iowa"},
                {"Kansas": "Kansas"},
                {"Kentucky": "Kentucky"},
                {"Louisiana": "Louisiana"},
                {"Maine": "Maine"},
                {"Maryland": "Maryland"},
                {"Massachusetts": "Massachusetts"},
                {"Michigan": "Michigan"},
                {"Minnesota": "Minnesota"},
                {"Mississippi": "Mississippi"},
                {"Missouri": "Missouri"},
                {"Montana": "Montana"},
                {"Nebraska": "Nebraska"},
                {"Nevada": "Nevada"},
                {"New Hampshire": "New Hampshire"},
                {"New Jersey": "New Jersey"},
                {"New Mexico": "New Mexico"},
                {"New York": "New York"},
                {"North Carolina": "North Carolina"},
                {"North Dakota": "North Dakota"},
                {"Ohio": "Ohio"},
                {"Oklahoma": "Oklahoma"},
                {"Oregon": "Oregon"},
                {"Pennsylvania": "Pennsylvania"},
                {"Rhode Island": "Rhode Island"},
                {"South Carolina": "South Carolina"},
                {"South Dakota": "South Dakota"},
                {"Tennessee": "Tennessee"},
                {"Texas": "Texas"},
                {"Utah": "Utah"},
                {"Vermont": "Vermont"},
                {"Virginia": "Virginia"},
                {"Washington": "Washington"},
                {"West Virginia": "West Virginia"},
                {"Wisconsin": "Wisconsin"},
                {"Wyoming": "Wyoming"}
            ],
            provinceOptions: [
                {"": "Select a city"},
                {"Calgary, AB": "Calgary, AB"},
                {"Charlottetown, PE": "Charlottetown, PE"},
                {"Edmonton, AB": "Edmonton, AB"},
                {"Halifax, NS": "Halifax, NS"},
                {"Montcon, NB": "Montcon, NB"},
                {"Montreal, QC": "Montreal, QC"},
                {"Ottawa, ON": "Ottawa, ON"},
                {"Regina, SK": "Regina, SK"},
                {"St. Johns, NL": "St. Johns, NL"},
                {"Toronto, ON": "Toronto, ON"},
                {"Vancouver, B.C.": "Vancouver, B.C."},
                {"Winnipeg, MB": "Winnipeg, MB"}
            ],
            commercialAvgPriceUS: '',
            commercialAvgPriceCA: '',
            wattageBeforeUS: '',
            wattageBeforeCA: '',
            wattageAfterUS: '',
            wattageAfterCA: '',
            fixturesBeforeUS: '',
            fixturesBeforeCA: '',
            fixturesAfterUS: '',
            fixturesAfterCA: '',
            lightingHoursUS: '24',
            lightingHoursCA: '24',
            lightingDaysUS: '365',
            lightingDaysCA: '365',
            installedCostUS: '',
            installedCostCA: '',
            totalFeetUS: '',
            totalFeetCA: '',
            allPricesUS: [],
            allPricesCA: [],
            priceUS: '',
            priceCA: '',
            state: '',
            province: '',
            totalEnergyPerHourBeforeUS: '',
            totalEnergyPerHourAfterUS: '',
            totalEnergyPerHourSavingsUS: '',
            totalEnergyPerDayBeforeUS: '',
            totalEnergyPerDayAfterUS: '',
            totalEnergyPerDaySavingsUS: '',
            totalEnergyPerAnnualBeforeUS: '',
            totalEnergyPerAnnualAfterUS: '',
            totalEnergyPerAnnualSavingsUS: '',
            totalCoatingInvestmentUS: '',
            yearsToPayUS: '0',
            totalEnergyPerHourBeforeCA: '',
            totalEnergyPerHourAfterCA: '',
            totalEnergyPerHourSavingsCA: '',
            totalEnergyPerDayBeforeCA: '',
            totalEnergyPerDayAfterCA: '',
            totalEnergyPerDaySavingsCA: '',
            totalEnergyPerAnnualBeforeCA: '',
            totalEnergyPerAnnualAfterCA: '',
            totalEnergyPerAnnualSavingsCA: '',
            totalCoatingInvestmentCA: '',
            yearsToPayCA: '0'
        }
    }

    async componentDidMount() {
        // Get all product data from airtable
        try {
            base('Average Price of Electricity in US').select({ view: 'All Prices' })
                .eachPage(
                    (records, fetchNextPage) => {
                        this.setState({
                            allPricesUS: records,
                        });
                        fetchNextPage();
                    }
                );

            base('Average Price of Electricity in CA').select({ view: 'All Prices' })
                .eachPage(
                    (records, fetchNextPage) => {
                        this.setState({
                            allPricesCA: records,
                        });
                        fetchNextPage();
                    }
                );
        } catch (err) {
            console.error(err)
        }

        // Get list of sectors from product data
    }

    handleCountrySelect = country => {
        this.setState({ selectedCountry: country })
    }

    handleStateProvinceChange = e => {
        e.persist();
        if (this.state.selectedCountry === 'usa') {
            const currentStatePriceUS = this.state.allPricesUS.find(price => price.fields.State === e.target.value)
            this.setState({priceUS: currentStatePriceUS.fields.Commercial})
        }
        else {
            const currentStatePriceCA = this.state.allPricesCA.find(price => price.fields.State === e.target.value)
            this.setState({priceCA: currentStatePriceCA.fields.Commercial})
        }
        
        this.setState({
            [e.target.name]: e.target.value,
        }, () => {
            this.calculation()
        })
        
    }

    handleEnergyChange = e => {
        e.persist();
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            this.calculation()
        })
    }

    calculation = () => {
            const totalEnergyPerHourBeforeUS = this.state.wattageBeforeUS * this.state.fixturesBeforeUS * this.state.priceUS / 100000
            const totalEnergyPerHourAfterUS = this.state.wattageAfterUS * this.state.fixturesAfterUS * this.state.priceUS / 100000
            const totalEnergyPerHourSavingsUS = totalEnergyPerHourBeforeUS - totalEnergyPerHourAfterUS
            const totalEnergyPerDayBeforeUS = this.state.lightingHoursUS * totalEnergyPerHourBeforeUS
            const totalEnergyPerDayAfterUS = this.state.lightingHoursUS * totalEnergyPerHourAfterUS
            const totalEnergyPerDaySavingsUS = totalEnergyPerDayBeforeUS - totalEnergyPerDayAfterUS
            const totalEnergyPerAnnualBeforeUS = totalEnergyPerDayBeforeUS * this.state.lightingDaysUS
            const totalEnergyPerAnnualAfterUS = totalEnergyPerDayAfterUS * this.state.lightingDaysUS
            const totalEnergyPerAnnualSavingsUS = totalEnergyPerAnnualBeforeUS - totalEnergyPerAnnualAfterUS
            
            const totalEnergyPerHourBeforeCA = this.state.wattageBeforeCA * this.state.fixturesBeforeCA * this.state.priceCA / 100000
            const totalEnergyPerHourAfterCA = this.state.wattageAfterCA * this.state.fixturesAfterCA * this.state.priceCA / 100000
            const totalEnergyPerHourSavingsCA = totalEnergyPerHourBeforeCA - totalEnergyPerHourAfterCA
            const totalEnergyPerDayBeforeCA = this.state.lightingHoursCA * totalEnergyPerHourBeforeCA
            const totalEnergyPerDayAfterCA = this.state.lightingHoursCA * totalEnergyPerHourAfterCA
            const totalEnergyPerDaySavingsCA = totalEnergyPerDayBeforeCA - totalEnergyPerDayAfterCA
            const totalEnergyPerAnnualBeforeCA = totalEnergyPerDayBeforeCA * this.state.lightingDaysCA
            const totalEnergyPerAnnualAfterCA = totalEnergyPerDayAfterCA * this.state.lightingDaysCA
            const totalEnergyPerAnnualSavingsCA = totalEnergyPerAnnualBeforeCA - totalEnergyPerAnnualAfterCA

            const totalCoatingInvestmentUS = this.state.installedCostUS * this.state.totalFeetUS
            const yearsToPayUS = totalCoatingInvestmentUS / totalEnergyPerAnnualSavingsUS
            const totalCoatingInvestmentCA = this.state.installedCostCA * this.state.totalFeetCA
            const yearsToPayCA = totalCoatingInvestmentCA / totalEnergyPerAnnualSavingsCA

            this.setState({
                totalEnergyPerHourBeforeUS: totalEnergyPerHourBeforeUS.toLocaleString(navigator.language, { style: 'currency', currency: 'USD' }),
                totalEnergyPerHourAfterUS: totalEnergyPerHourAfterUS.toLocaleString(navigator.language, { style: 'currency', currency: 'USD' }),
                totalEnergyPerHourSavingsUS: totalEnergyPerHourSavingsUS.toLocaleString(navigator.language, { style: 'currency', currency: 'USD' }),
                totalEnergyPerDayBeforeUS: totalEnergyPerDayBeforeUS.toLocaleString(navigator.language, { style: 'currency', currency: 'USD' }),
                totalEnergyPerDayAfterUS: totalEnergyPerDayAfterUS.toLocaleString(navigator.language, { style: 'currency', currency: 'USD' }),
                totalEnergyPerDaySavingsUS: totalEnergyPerDaySavingsUS.toLocaleString(navigator.language, { style: 'currency', currency: 'USD' }),
                totalEnergyPerAnnualBeforeUS: totalEnergyPerAnnualBeforeUS.toLocaleString(navigator.language, { style: 'currency', currency: 'USD' }),
                totalEnergyPerAnnualAfterUS: totalEnergyPerAnnualAfterUS.toLocaleString(navigator.language, { style: 'currency', currency: 'USD' }),
                totalEnergyPerAnnualSavingsUS: totalEnergyPerAnnualSavingsUS.toLocaleString(navigator.language, { style: 'currency', currency: 'USD' }),
                totalCoatingInvestmentUS: totalCoatingInvestmentUS.toLocaleString(navigator.language, { style: 'currency', currency: 'USD' }),
                yearsToPayUS: yearsToPayUS.toLocaleString(undefined, { maximumFractionDigits:1 }),
                totalEnergyPerHourBeforeCA: totalEnergyPerHourBeforeCA.toLocaleString(navigator.language, { style: 'currency', currency: 'USD' }),
                totalEnergyPerHourAfterCA: totalEnergyPerHourAfterCA.toLocaleString(navigator.language, { style: 'currency', currency: 'USD' }),
                totalEnergyPerHourSavingsCA: totalEnergyPerHourSavingsCA.toLocaleString(navigator.language, { style: 'currency', currency: 'USD' }),
                totalEnergyPerDayBeforeCA: totalEnergyPerDayBeforeCA.toLocaleString(navigator.language, { style: 'currency', currency: 'USD' }),
                totalEnergyPerDayAfterCA: totalEnergyPerDayAfterCA.toLocaleString(navigator.language, { style: 'currency', currency: 'USD' }),
                totalEnergyPerDaySavingsCA: totalEnergyPerDaySavingsCA.toLocaleString(navigator.language, { style: 'currency', currency: 'USD' }),
                totalEnergyPerAnnualBeforeCA: totalEnergyPerAnnualBeforeCA.toLocaleString(navigator.language, { style: 'currency', currency: 'USD' }),
                totalEnergyPerAnnualAfterCA: totalEnergyPerAnnualAfterCA.toLocaleString(navigator.language, { style: 'currency', currency: 'USD' }),
                totalEnergyPerAnnualSavingsCA: totalEnergyPerAnnualSavingsCA.toLocaleString(navigator.language, { style: 'currency', currency: 'USD' }),
                totalCoatingInvestmentCA: totalCoatingInvestmentCA.toLocaleString(navigator.language, { style: 'currency', currency: 'USD' }),
                yearsToPayCA: yearsToPayCA.toLocaleString(undefined, { maximumFractionDigits:1 }),
            })
    }

    copyCalcDataToClipboard = () => {
        let calculatorDataString
        if (this.state.selectedCountry === 'usa') {
            calculatorDataString = `Energy expenditure on lighting (USD)\nPer Hour\nBefore - After - Savings\n${this.state.totalEnergyPerHourBeforeUS} - ${this.state.totalEnergyPerHourAfterUS} - ${this.state.totalEnergyPerHourSavingsUS}\nPer Day\nBefore - After - Savings\n${this.state.totalEnergyPerDayBeforeUS} - ${this.state.totalEnergyPerDayAfterUS} - ${this.state.totalEnergyPerDaySavingsUS}\nAnnual\nBefore - After - Savings\n${this.state.totalEnergyPerAnnualBeforeUS} - ${this.state.totalEnergyPerAnnualAfterUS} - ${this.state.totalEnergyPerAnnualSavingsUS}\nTotal coating investment\n${this.state.totalCoatingInvestmentUS}\nTime for MasterProtect HB 200 LR to pay for itself\n${this.state.yearsToPayUS} years`
        } else {
            calculatorDataString = `Energy expenditure on lighting (CAD)\nPer Hour\nBefore - After - Savings\n${this.state.totalEnergyPerHourBeforeCA} - ${this.state.totalEnergyPerHourAfterCA} - ${this.state.totalEnergyPerHourSavingsCA}\nPer Day\nBefore - After - Savings\n${this.state.totalEnergyPerDayBeforeCA} - ${this.state.totalEnergyPerDayAfterCA} - ${this.state.totalEnergyPerDaySavingsCA}\nAnnual\nBefore - After - Savings\n${this.state.totalEnergyPerAnnualBeforeCA} - ${this.state.totalEnergyPerAnnualAfterCA} - ${this.state.totalEnergyPerAnnualSavingsCA}\nTotal coating investment\n${this.state.totalCoatingInvestmentCA}\nTime for MasterProtect HB 200 LR to pay for itself\n${this.state.yearsToPayCA} years`
        }
        navigator.clipboard.writeText(calculatorDataString)
        notify()
    }

    render() {
        return (
            <SiteContext.Consumer>
                {siteContext => (
                    <div className="site-masterprotect">
                        <Layout>
                            <SEO title="MasterSeal Traffic Coating Selector Tool" description="" />
                            <div className="wrapper wrapper--tool">
                                <div className="content-wrapper">
                                    <div className="intro-content">
                                        <div>
                                            <h1> { this.state.siteTitle } </h1>
                                            <p> { this.state.introContent} </p>
                                        </div>
                                        <img src={ProtectIntroImage} alt="" /> 
                                    </div>
                                    <div className="country-toggle">
                                        <div className={`country-toggle__wrapper ${this.state.selectedCountry === 'usa' ? 'active' : ''}`} onClick={() => this.handleCountrySelect('usa')}>  
                                            <div className={`country-toggle__button`}>
                                                <img src={usaFlag} /> 
                                                USA 
                                            </div>
                                        </div>
                                        <div className={`country-toggle__wrapper ${this.state.selectedCountry === 'canada' ? 'active' : ''}`} onClick={() => this.handleCountrySelect('canada')}>
                                            <div className={`country-toggle__button`}>
                                                <img src={canadaFlag} />
                                                Canada
                                            </div>
                                        </div>
                                    </div>
                                    <div className="energy-cost-estimator">
                                        <div className="energy-cost-calculator">
                                            <div className="energy-cost__title">
                                                <div className="energy-cost__icon">
                                                    <img src={lightningIcon} />
                                                </div>
                                                <h2>Energy Cost Savings Estimator</h2>
                                            </div>
                                            <div className="calculator-group">
                                                <fieldset>
                                                    <legend>Wattage of Light Sources</legend>

                                                    <div>
                                                        <label for="wattage-before">Before</label> 
                                                        {this.state.selectedCountry === 'usa' ?
                                                            <input type="number" name="wattageBeforeUS" value={this.state.wattageBeforeUS} onChange={this.handleEnergyChange} placeholder="0"></input>
                                                        :
                                                            <input type="number" name="wattageBeforeCA" value={this.state.wattageBeforeCA} onChange={this.handleEnergyChange} placeholder="0"></input>
                                                        }
                                                    </div>

                                                    <div>
                                                        <label for="wattage-after">After</label>
                                                        {this.state.selectedCountry === 'usa' ?
                                                            <input type="number" name="wattageAfterUS" value={this.state.wattageAfterUS} onChange={this.handleEnergyChange} placeholder="0"></input>
                                                        :
                                                            <input type="number" name="wattageAfterCA" value={this.state.wattageAfterCA} onChange={this.handleEnergyChange} placeholder="0"></input>
                                                        }
                                                    </div>
                                                </fieldset>

                                                <fieldset>
                                                    <legend>Number of Fixtures/Bulbs/etc.</legend>

                                                    <div>
                                                        <label for="fixtures-before">Before</label>
                                                        {this.state.selectedCountry === 'usa' ?
                                                            <input type="number" name="fixturesBeforeUS" value={this.state.fixturesBeforeUS} onChange={this.handleEnergyChange} placeholder="0"></input>
                                                        :
                                                            <input type="number" name="fixturesBeforeCA" value={this.state.fixturesBeforeCA} onChange={this.handleEnergyChange} placeholder="0"></input>
                                                        }
                                                    </div>
                                                    
                                                    <div>
                                                        <label for="fixtures-after">After</label>
                                                        {this.state.selectedCountry === 'usa' ?
                                                            <input type="number" name="fixturesAfterUS" value={this.state.fixturesAfterUS} onChange={this.handleEnergyChange} placeholder="0"></input>
                                                        :
                                                            <input type="number" name="fixturesAfterCA" value={this.state.fixturesAfterCA} onChange={this.handleEnergyChange} placeholder="0"></input>
                                                        }
                                                    </div>
                                                </fieldset>
                                            </div>

                                            <div className="calculator-group">
                                                <fieldset>
                                                    <div>
                                                        <label for="state">{this.state.selectedCountry === 'usa' ? 'State' : 'City'}</label>
                                                        {(() => {
                                                            if (this.state.selectedCountry === 'usa') {
                                                                const options = this.state.stateOptions.map((key, val) => <option value={Object.keys(key)[0]}>{Object.values(key)[0]}</option> )
                                                                return <select name="state" value={this.state.state} onChange={this.handleStateProvinceChange}> {options} </select>
                                                            }
                                                            else {
                                                                const options = this.state.provinceOptions.map((key, val) => <option value={Object.keys(key)[0]}>{Object.values(key)[0]}</option> )
                                                                return <select name="province" value={this.state.province} onChange={this.handleStateProvinceChange}> {options} </select>
                                                            }
                                                        }) ()}
                                                    </div>
                                                </fieldset>
                                                <fieldset>
                                                    <div className="cost-disclaimer">
                                                        {this.state.selectedCountry === 'usa' ?
                                                            <p>States 2020 Commercial Energy Cost* <br /><span className="cost-disclaimer__result">{this.state.priceUS} &#162;/kWh</span></p>
                                                        :
                                                            <p>"Small Power" Energy Cost* <br /><span className="cost-disclaimer__result">{this.state.priceCA} &#162;/kWh</span></p>
                                                        }
                                                    </div>
                                                </fieldset>
                                            </div>

                                            <div className="calculator-group">
                                                <fieldset>
                                                    <div>
                                                        <legend>Average Daily lighting period (1-24 hours)</legend>
                                                        {this.state.selectedCountry === 'usa' ?
                                                            <input type="number" name="lightingHoursUS" value={this.state.lightingHoursUS} onChange={this.handleEnergyChange}></input>
                                                        :
                                                            <input type="number" name="lightingHoursCA" value={this.state.lightingHoursCA} onChange={this.handleEnergyChange}></input>
                                                        }
                                                    </div>
                                                </fieldset>

                                                <fieldset>
                                                    <div>
                                                        <legend>Number of lighting days (1-365 days)</legend>
                                                        {this.state.selectedCountry === 'usa' ?
                                                            <input type="number" name="lightingDaysUS" value={this.state.lightingDaysUS} onChange={this.handleEnergyChange}></input>
                                                        :
                                                            <input type="number" name="lightingDaysCA" value={this.state.lightingDaysCA} onChange={this.handleEnergyChange}></input>
                                                        }
                                                    </div>
                                                </fieldset>
                                            </div>
                                        </div>
                                        <div className="energy-cost-results">
                                            {this.state.selectedCountry === 'usa' ?
                                                <h2>Energy expenditure on lighting (USD)</h2>
                                            :
                                                <h2>Energy expenditure on lighting (CAD)</h2>
                                            }
                                            
                                            <div className="results-group">
                                                <h3><strong>Per Hour</strong></h3>
                                                <div>
                                                    {this.state.selectedCountry === 'usa' ? <div>Before <span>{this.state.totalEnergyPerHourBeforeUS}</span></div> : <div>Before<span>{this.state.totalEnergyPerHourBeforeCA}</span></div> }
                                                    {this.state.selectedCountry === 'usa' ? <div>After <span>{this.state.totalEnergyPerHourAfterUS}</span></div> : <div>After<span>{this.state.totalEnergyPerHourAfterCA}</span></div> }
                                                    {this.state.selectedCountry === 'usa' ? <div>Savings<span className="results--savings">{this.state.totalEnergyPerHourSavingsUS}</span></div> : <div>Savings <span className="results--savings">{this.state.totalEnergyPerHourSavingsCA}</span></div> }
                                                </div>
                                            </div>
                                            <div className="results-group">
                                                <h3><strong>Per Day</strong></h3>
                                                <div>
                                                    {this.state.selectedCountry === 'usa' ? <div>Before <span>{this.state.totalEnergyPerDayBeforeUS}</span></div> : <div>Before <span>{this.state.totalEnergyPerDayBeforeCA}</span></div> }
                                                    {this.state.selectedCountry === 'usa' ? <div>After <span>{this.state.totalEnergyPerDayAfterUS}</span></div> : <div>After <span>{this.state.totalEnergyPerDayAfterCA}</span></div> }
                                                    {this.state.selectedCountry === 'usa' ? <div>Savings <span className="results--savings">{this.state.totalEnergyPerDaySavingsUS}</span></div> : <div>Savings <span className="results--savings">{this.state.totalEnergyPerDaySavingsCA}</span></div> }
                                                </div>
                                            </div>
                                            <div className="results-group">
                                                <h3><strong>Annual</strong></h3>
                                                <div>
                                                    {this.state.selectedCountry === 'usa' ? <div>Before <span>{this.state.totalEnergyPerAnnualBeforeUS}</span></div> : <div>Before <span>{this.state.totalEnergyPerAnnualBeforeCA}</span></div> }
                                                    {this.state.selectedCountry === 'usa' ? <div>After <span>{this.state.totalEnergyPerAnnualAfterUS}</span></div> : <div> After<span>{this.state.totalEnergyPerAnnualAfterCA}</span></div> }
                                                    {this.state.selectedCountry === 'usa' ? <div>Savings <span className="results--savings">{this.state.totalEnergyPerAnnualSavingsUS}</span></div> : <div>Savings <span className="results--savings">{this.state.totalEnergyPerAnnualSavingsCA}</span></div> }
                                                </div>
                                            </div>
                                            {/* <div className="results-group">
                                                <div>
                                                    {this.state.selectedCountry === 'usa' ? <p><strong>Total coating investment</strong><br/>{this.state.totalCoatingInvestmentUS}</p> : <p><strong>Total coating investment</strong><br/>{this.state.totalCoatingInvestmentCA}</p> }
                                                    {this.state.selectedCountry === 'usa' ? <p><strong>Years for MasterProtect HB 200 LR to pay for itself</strong><br/>{this.state.yearsToPayUS} Years</p> : <p><strong>Years for MasterProtect HB 200 LR to pay for itself</strong><br/>{this.state.yearsToPayCA} Years</p> }
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>

                                    <div className="roi-estimator">
                                        <div className="roi-estimator__title">
                                            <div className="roi-estimator__icon">
                                                <img src={timeIcon} />
                                            </div>
                                            <h2>Return on Investment Estimator</h2>
                                        </div>
                                        <div className="calculator-group">
                                            <fieldset>
                                                <div>
                                                        {this.state.selectedCountry === 'usa' ? 
                                                            <legend>Installed cost per ft<sup>2</sup> ($)</legend>
                                                        :
                                                            <legend>Installed cost per m<sup>2</sup> ($)</legend>
                                                        }
                                                        
                                                        {this.state.selectedCountry === 'usa' ?
                                                            <input type="number" name="installedCostUS" value={this.state.installedCostUS} onChange={this.handleEnergyChange}></input>
                                                        :
                                                            <input type="number" name="installedCostCA" value={this.state.installedCostCA} onChange={this.handleEnergyChange}></input>
                                                        }
                                                </div>
                                            </fieldset>

                                            <fieldset>
                                                <div>
                                                    {this.state.selectedCountry === 'usa' ?
                                                        <legend>Total ft<sup>2</sup></legend>
                                                        :
                                                        <legend>Total m<sup>2</sup></legend>
                                                    }

                                                    {this.state.selectedCountry === 'usa' ?
                                                        <input type="number" name="totalFeetUS" value={this.state.totalFeetUS} onChange={this.handleEnergyChange}></input>
                                                        :
                                                        <input type="number" name="totalFeetCA" value={this.state.totalFeetCA} onChange={this.handleEnergyChange}></input>
                                                    }
                                                </div>
                                            </fieldset>
                                        </div>
                                        { this.state.selectedCountry === 'usa' && this.state.yearsToPayUS !== "0" && this.state.yearsToPayUS !== "NaN" && this.state.yearsToPayUS !== "∞" ?
                                            <div className="results-promoted">
                                                <div className="results-promoted__result"><span>{this.state.totalCoatingInvestmentUS}</span> Total coating investment </div> 
                                                <div className="results-promoted__result"><span>{this.state.yearsToPayUS} Years</span> Time for MasterProtect HB 200 LR to pay for itself</div> 
                                            </div>
                                        : null
                                        } 
                                        { this.state.selectedCountry === 'canada' && this.state.yearsToPayCA !== "0" && this.state.yearsToPayCA !== "NaN" && this.state.yearsToPayCA !== "∞" ?
                                            <div className="results-promoted"> 
                                                <div className="results-promoted__result"><span>{this.state.totalCoatingInvestmentCA}</span> Total coating investment </div> 
                                                <div className="results-promoted__result"><span>{this.state.yearsToPayCA} Years</span> Time for MasterProtect HB 200 LR to pay for itself</div> 
                                            </div>
                                        : null
                                        } 




                                    </div>

                                    <div className={`masterprotect-result-buttons 
                                        ${ this.state.selectedCountry === 'usa' && this.state.yearsToPayUS !== "0" && this.state.yearsToPayUS !== "NaN" && this.state.yearsToPayUS !== "∞" || this.state.selectedCountry === 'canada' && this.state.yearsToPayCA !== "0" && this.state.yearsToPayCA !== "NaN" && this.state.yearsToPayCA !== "∞" 
                                            ? 'active'
                                            : ''
                                        }`}>  
                                        <ToastContainer
                                            className="calculator-toast masterprotect"
                                            autoClose={2000}
                                            hideProgressBar={true}
                                            transition={Zoom}
                                        />
                                        {/* <span className="btn-square">
                                            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.7" d="M16.9999 12.3478V16.1104C16.9999 16.1857 16.9891 16.2551 16.9732 16.3195C16.8979 16.6891 16.5717 16.9668 16.1862 16.9668H0.808734C0.396422 16.9668 0.0543771 16.6506 0.0108722 16.2492C5.34379e-08 16.2007 0 16.158 0 16.1045V12.3419C0 11.8978 0.363797 11.5391 0.802871 11.5391C1.02198 11.5391 1.22606 11.6302 1.36989 11.7749C1.51458 11.9196 1.60574 12.1228 1.60574 12.3419V15.3552H15.3993V12.3419C15.3993 11.8978 15.7631 11.5391 16.2022 11.5391C16.4213 11.5391 16.6253 11.6302 16.7692 11.7749C16.9088 11.9246 17 12.1278 17 12.3478L16.9999 12.3478Z" fill="#7C7C7C"/><path opacity="0.7" d="M13.1303 8.57985L9.18532 12.5248C9.1803 12.5357 9.16943 12.5407 9.16358 12.5466C9.01889 12.6913 8.83155 12.7824 8.64421 12.8092C8.62832 12.8092 8.61243 12.8142 8.59571 12.8142C8.56393 12.8192 8.53131 12.8192 8.49953 12.8192L8.40837 12.8142C8.39248 12.8142 8.37659 12.8092 8.35986 12.8092C8.16751 12.7824 7.98519 12.6913 7.8405 12.5466C7.83549 12.5416 7.82461 12.5307 7.81876 12.5248L3.87377 8.57985C3.69145 8.39753 3.60114 8.15666 3.60114 7.9158C3.60114 7.67494 3.69229 7.43408 3.87377 7.25175C4.23757 6.88795 4.8322 6.88795 5.20101 7.25175L7.57195 9.62268L7.57445 0.936683C7.57445 0.423188 7.99764 0 8.51114 0C8.76789 0 9.00374 0.10705 9.17519 0.272634C9.34663 0.44408 9.44782 0.674069 9.44782 0.936683V9.62354L11.8188 7.2526C12.1826 6.88881 12.7772 6.88881 13.146 7.2526C13.4939 7.62227 13.4939 8.21605 13.1301 8.57985H13.1303Z" fill="#7C7C7C"/></svg>
                                            Export Results</span> */}
                                        <span className="btn-square" onClick={this.state.selectedCountry === 'usa' && this.state.yearsToPayUS !== "0" && this.state.yearsToPayUS !== "NaN" && this.state.yearsToPayUS !== "∞" || this.state.selectedCountry === 'canada' && this.state.yearsToPayCA !== "0" && this.state.yearsToPayCA !== "NaN" && this.state.yearsToPayCA !== "∞" ? this.copyCalcDataToClipboard : null}>
                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><g opacity="0.7" clip-path="url(#clip0)"><path d="M7.50034 13.5001V14.9998H9.37486H0.751064C0.336218 14.9998 0.00012207 14.6658 0.00012207 14.251V0.748836C0.00012207 0.335394 0.33341 0 0.751064 0H11.2494C11.6642 0 12.0003 0.33399 12.0003 0.748836V9.75028V5.25028H10.4999V1.4998H1.49986V13.5H3.74986V14.9998H5.99986V13.5L7.50034 13.5001ZM10.4999 1.49983V3.74983H12.0003V1.49983H10.4999ZM5.99986 4.50151C5.99986 4.08667 6.33385 3.74987 6.75081 3.74987H17.2491C17.664 3.74987 18.0001 4.08807 18.0001 4.50151V17.2491C18.0001 17.664 17.6668 18.0001 17.2491 18.0001H6.75081C6.33596 18.0001 5.99986 17.6619 5.99986 17.2491V4.50151ZM7.50033 16.5003H16.5003V5.25031H7.50033V16.5003ZM3.00033 14.2503C3.00033 13.8362 3.33712 13.5001 3.74916 13.5001H4.50081C4.91495 13.5001 5.25034 13.8327 5.25034 14.2503C5.25034 14.6645 4.91355 14.9998 4.50081 14.9998H3.74916C3.33572 14.9998 3.00033 14.6673 3.00033 14.2503ZM11.2501 0.750312C11.6642 0.750312 12.0003 1.08711 12.0003 1.49915V2.25079C12.0003 2.66494 11.667 3.00033 11.2501 3.00033C10.8359 3.00033 10.4998 2.66353 10.4998 2.25079V1.49915C10.4998 1.08571 10.8331 0.750312 11.2501 0.750312Z" fill="#7C7C7C"/></g><defs><clipPath id="clip0"><rect width="18" height="18" fill="white"/></clipPath></defs></svg>
                                            Copy Results
                                        </span>
                                    </div>

                                    <div className="masterprotect-disclaimer">
                                        { this.state.selectedCountry === 'usa' ? 
                                            <div>
                                            <p>*Source - U.S. Energy Information Administration Table 5.6.A. Average Retail Price of Electricity to Ultimate Customers by End-Use Sector (August 2020 - Commercial)</p>
                                            <p>Disclaimer - MasterProtect HB 200 LR Energy Cost Savings Estimator and the MasterProtect HB 200 LR Return on Investment Estimator are meant to help estimate potential energy savings and return on investment and are not intended to be an empirical predictor of savings through the use of MasterProtect HB 200 LR.</p>
                                            </div>
                                        :
                                            <div>
                                            <p>*Source - Comparison of Electricity Prices in Major North American Cities, Hydro Quebec, April 2020, Canadian Dollars - Page 22 </p>
                                            <p>Disclaimer - MasterProtect HB 200 LR Energy Cost Savings Estimator and the MasterProtect HB 200 LR Return on Investment Estimator are meant to help estimate potential energy savings and return on investment and are not intended to be an empirical predictor of savings through the use of MasterProtect HB 200 LR.</p>
                                            </div>
                                        }
                                        
                                    </div>
                                    <LowDustBox 
                                        lowDustBoxImage={lowDustImg} 
                                        lowDustBoxTitle={this.state.lowDustBoxTitle}
                                        lowDustBoxContent={this.state.lowDustBoxContent}
                                        lowDustBoxLink={this.state.lowDustBoxLink}
                                    />
                                </div>
                            </div>
                        </Layout>
                    </div>
                )}
            </SiteContext.Consumer>
        )
    }
}

export default WelcomeMasterprotect